<template>
  <article>
    <template v-if="allComponents">
      <div v-for="el in allComponents" :key="el.timestamp">
        <homepage-component-wrapper :cms-component="el" />
      </div>
    </template>

    <GradientCta />
  </article>
</template>

<script>
import { buildTitleAndMeta } from '../services/utils';
import {
  AMPLITUDE_EVENT_PROPERTIES,
  EVENT_NAMES
} from '../services/analyticsEvents';

export default {
  async asyncData({ store }) {
    const res = await store.dispatch('cmsStore/getHomepageV2');
    return { allComponents: res.components };
  },
  head() {
    return buildTitleAndMeta({
      canonicalExt: '/'
    });
  },
  mounted() {
    this.$store.dispatch('analytics/amplitudeStore/logEvent', {
      name: EVENT_NAMES.pageViewed,
      properties: {
        [AMPLITUDE_EVENT_PROPERTIES.source]: 'homepage'
      }
    });
  },
  jsonld() {
    return {
      '@context': 'https://schema.org/',
      '@type': 'Organization',
      name: 'KnownOrigin',
      legalName: 'KnownOrigin Labs Limited',
      url: 'https://knownorigin.io/',
      logo: 'https://knownorigin.io/branding/KO-logo-169ratio.jpg',
      description: 'An artist-driven NFT Digital Art Marketplace',
      sameAs: [
        'https://twitter.com/knownorigin_io',
        'https://discord.gg/knownorigin',
        'https://www.instagram.com/knownorigin.io/',
        'https://www.reddit.com/r/knownorigin/',
        'https://www.youtube.com/channel/UCZBkUkm5SSXQqzxBPezd5gw',
        'https://t.me/knownorigin_io',
        'https://docs.knownorigin.io',
        'https://linktr.ee/KnownOrigin.io'
      ]
    };
  }
};
</script>
