var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    [
      _vm.allComponents
        ? _vm._l(_vm.allComponents, function (el) {
            return _c(
              "div",
              { key: el.timestamp },
              [
                _c("homepage-component-wrapper", {
                  attrs: { "cms-component": el },
                }),
              ],
              1
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c("GradientCta"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }